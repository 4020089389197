

<template>
  <div id="box" class="home-container">

    <div class="box_content">
      <div class="box_content_title">忘记密码</div>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">

        <el-row :gutter="20" style="margin-bottom: 10px">
          <el-col :span="4" style="color: transparent">1</el-col>
          <el-col :span="8">
            <el-form-item label="信用代码" prop="yscode">
              <el-input v-model="ruleForm.yscode" placeholder="请输入单位信用代码"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="手机号" prop="phone">
              <el-input v-model="ruleForm.phone"  placeholder="请输入手机号码" maxlength="11"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20" style="margin-bottom: 10px">
          <el-col :span="4" style="color: transparent">1</el-col>
          <el-col :span="8">
            <el-form-item label="邮箱" prop="email">
              <el-input v-model="ruleForm.email"  placeholder="请输入邮箱"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="验证码" prop="code">
              <div style="display: flex;flex-direction: row">
                <el-input style="display:block;width: 60%" v-model="ruleForm.code"  placeholder="请输入邮箱验证码"></el-input>
                <div style="" class="emailcode_anniu" @click="startCountdown">{{ countdown > 0 ? `${countdown}秒后重新发送` : '发送验证码' }}</div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20" style="margin-bottom: 10px">
          <el-col :span="4" style="color: transparent">1</el-col>
          <el-col :span="8">
            <el-form-item label="密码" prop="password1">
              <el-input v-model="ruleForm.password1" maxlength="16" show-password placeholder="请输入密码"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="确认密码" prop="password">
              <el-input v-model="ruleForm.password" maxlength="16" show-password placeholder="请输入二次确认密码"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <div style="display: flex;flex-direction: column;margin-left: 0;">
          <el-button style="width: 520px;background: #f39800 ;border-color: #f39800;margin: 10px auto;" type="primary" @click="submitForm('ruleForm')">立即修改</el-button>
          <el-button style="margin: 10px auto;width: 520px" @click="resetForm('ruleForm')">返回登录</el-button>
        </div>
      </el-form>
    </div>

  </div>
</template>

<script>
import {conf_list, mail_send_email_code, mallOrderdata_update_user_password, user_register} from "@/api/manage";
import {removeToken} from "@/utils/auth";
export default {
  components: {},
  data() {
    var validatePass1 = (rule, value, callback) => {
      var regex = /^1[3456789]\d{9}$/
      if (value === '') {
        callback(new Error('请输入手机号！'));
      } else if(!regex.test(value)){
        callback(new Error('手机号不正确！'));
      }else{
        callback()
      }
    };
    var validatePass2 = (rule, value, callback) => {
      var pattern = /^[a-zA-Z0-9_-]+@([a-zA-Z0-9]+\.)+(com|cn|net|org)$/
      if (value === '') {
        callback(new Error('请输入邮箱！'))
      } else if(!pattern.test(value)){
        callback(new Error('请检查邮箱格式！'))
      }else{
        callback();
      }
    };
    var validatePass3 = (rule, value, callback) => {
      var regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()\-+.]).{6,16}$/;
      if (value === '') {
        callback(new Error('请输入密码！'))
      } else if(!regex.test(value)){
        callback(new Error('请检查密码格式！（最少包含一位大写英文字符+一位小写英文字符+一位数字+长度6-16为之间+一位特殊字符）'))
      }else{
        callback();
      }
    };
    return {
      countdown: 0, // 倒计时的秒数
      intervalId: null, // 存储interval的id

      httplogo:'',
      fontSize: 26, // 默认字体大小:
      ruleForm: {
        yscode:'',
        phone:'',
        email:'',
        password:'',
        code:'',
      },
      rules: {
        yscode: [
          { required: true, message: '请输入信用代码', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '请输入经办人手机号码', trigger: 'blur' },
          { min: 11, max: 11, message: '长度在 11个字符', trigger: 'blur' },
          { validator: validatePass1, trigger: 'blur' }
          // {pattern: /^1[3456789]\d{9}$/, message: "请输入正确的手机号码!"}
        ],
        email: [
          { required: true, message: '请输入电子邮箱', trigger: 'blur' },
          { validator: validatePass2, trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入电子邮箱验证码', trigger: 'blur' },
        ],
        password1:[
          { required: true, message: '请输入登录密码', trigger: 'blur' },
          { min: 6, max: 16, message: '长度在 6-16个字符之间', trigger: 'blur' },
          { validator: validatePass3, trigger: 'blur' }
        ],
        password:[
          { required: true, message: '请二次确认登录密码', trigger: 'blur' },
          { min: 6, max: 16, message: '长度在 6-16个字符之间', trigger: 'blur' },
          { validator: validatePass3, trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    this.setFontSize(); // 组件创建时设置字体大小
    window.addEventListener('resize', this.setFontSize); // 监听窗口大小变化
    this.httplogo = "/imgs/" + window.location.hostname + "/logo.png"
    var configdata =  JSON.parse(localStorage.getItem('configdata'))
    this.configdata = configdata
    this.province = configdata.province
  },
  destroyed() {
    window.removeEventListener('resize', this.setFontSize); // 移除监听器
  },
  methods: {
    //点击发送出现60秒倒计时
    startCountdown() {
      if (this.intervalId) return;
      var email = this.ruleForm.email
      var pattern = /^[a-zA-Z0-9_-]+@([a-zA-Z0-9]+\.)+(com|cn|net|org)$/
      if(!pattern.test(email)){
        this.$message({
          message: '请检查邮箱格式！',
          type: 'warning'
        })
        return false
      }else if(this.ruleForm.phone.length != 11){
        this.$message({
          message: '请填写正确手机号！',
          type: 'warning'
        })
        return false
      }else {
        mail_send_email_code({email: this.ruleForm.email,phone:this.ruleForm.phone}).then((response) => {
          this.$message({
            message: '发送成功！',
            type: 'success'
          });
          this.countdown = 60; // 倒计时60秒
          this.intervalId = setInterval(() => {
            if (this.countdown > 0) {
              this.countdown -= 1;
            } else {
              clearInterval(this.intervalId);
              this.intervalId = null;
            }
          }, 1000);
        })
      }
    },
      home(){
      this.$router.push("/")
    },
    setFontSize() {
      const baseFontSize = 20; // 基准字体大小
      const maxFontSize = 28; // 最大字体大小
      const minFontSize = 25; // 最小字体大小
      const screenWidth = window.innerWidth; // 获取屏幕宽度
      const widthRate = screenWidth / 1920; // 宽度缩放比率（假设1920px设计稿宽度）

      this.fontSize = Math.min(maxFontSize, Math.max(minFontSize, baseFontSize * widthRate));
    },
    submitForm(formName) {
      // var yscode = this.ruleForm.yscode
      // this.ruleForm.yscode = yscode.replace(/\s/g, "")
      // console.log(this.ruleForm.yscode,'this.ruleForm.yscode')
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(this.ruleForm.password1 != this.ruleForm.password){
            this.$message({
              message: '两次密码不一致，请检查！',
              type: 'warning'
            });
            return false
          }else{
            var yscode = this.ruleForm.yscode
            this.ruleForm.yscode = yscode.replace(/\s/g, "")
            mallOrderdata_update_user_password(this.ruleForm).then((response) => {
              if(response.code == 0){
                this.$message({
                  message: '修改成功！',
                  type: 'success'
                });
                removeToken()
                localStorage.removeItem("setToken");
                localStorage.removeItem("companyinfo");
                this.$router.push("/login")

              }
            })
          }

        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$router.push("/login");
      // this.$refs[formName].resetFields();
    }
  }
}
</script>

<style scoped lang="css">
#box {
  background-color:#fff;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  //height: 100vh;
}

.box_banner_logo{
  display: flex;
  flex-direction: row;
  width:100%;
  height: 100px;
  font-weight: bold;
  line-height:80px;
  padding: 10px 10%;
  box-sizing: border-box;

}

.emailcode_anniu{
  display:block;
  width: 40%;
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  margin-left: 10px;
  text-align: center;
  background: #f39800;
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
}



.box_content{
  display: flex;
  flex-direction: column;
  padding: 50px 100px;
  box-sizing: border-box;
}

.box_content_title{
  display: block;
  width: 100%;
  height: 100px;
  line-height: 100px;
  font-size: 50px;
  font-weight: bold;
  text-align: center;
  font-family: cursive;
  margin-bottom: 50px;

}

</style>